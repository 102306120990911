// import './bootstrap';
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { createInertiaApp } from "@inertiajs/react";
import { AuthProvider } from "./Context/";

const appName =
    window.document.getElementsByTagName("title")[0]?.innerText || "Calentrio";

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => require(`./Pages/${name}`),
    setup({ el, App, props }) {
        const root = createRoot(el);

        root.render(
            <StrictMode>
                <AuthProvider>
                    <App {...props} />
                </AuthProvider>
            </StrictMode>
        );
    },
    progress: {
        color: "#4B5563",
    },
});
