import { useMemo } from "react";
import CalendarModule from "./CalendarModule";
import state from "../../Store";
const Calendar = () => {
    const today = new Date();
    useMemo(() => {
        state.today = today;
        state.currentMonth = today.getMonth();
        state.currentYear = today.getFullYear();
    }, []);
    return (
        <div className="calendar-wrapper">
            <div className="mb-7">
                <CalendarModule />
            </div>
        </div>
    );
};
export default Calendar;
Calendar.propTypes = {};

Calendar.defaultProps = {};
