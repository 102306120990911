import { useState } from "react";
import ApplicationLogo from "../ApplicationLogo";
import Dropdown from "../Dropdown";
import NavLink from "../NavLink";
import ResponsiveNavLink from "../ResponsiveNavLink";
import { Link } from "@inertiajs/react";
import { twMerge } from "tailwind-merge";

export default function Nav({ auth }) {
    const [showingNavigationDropdown, setShowingNavigationDropdown] =
        useState(false);
    return (
        <nav className="navigation">
            <div className="mx-auto px-4 lg:px-0">
                <div className="flex justify-between h-16 relative items-center">
                    <div className="shrink-0 flex items-center">
                        <Link href="/">
                            <ApplicationLogo className="block h-9 w-auto fill-current text-gray-800" />
                        </Link>
                    </div>
                    <div className="flex flex-col">
                        <div className="-mr-2 flex items-center sm:hidden">
                            <button
                                onClick={() =>
                                    setShowingNavigationDropdown(
                                        (previousState) => !previousState
                                    )
                                }
                                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                            >
                                <svg
                                    className="h-6 w-6"
                                    stroke="currentColor"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        className={
                                            !showingNavigationDropdown
                                                ? "inline-flex"
                                                : "hidden"
                                        }
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                    <path
                                        className={
                                            showingNavigationDropdown
                                                ? "inline-flex"
                                                : "hidden"
                                        }
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                        {/* <div
                            className={twMerge(
                                `${
                                    showingNavigationDropdown
                                        ? "link_wrapper_mobile_active sm:link_wrapper"
                                        : "link_wrapper_mobile sm:link_wrapper"
                                } sm:flex`,
                                "link_wrapper"
                            )}
                        > */}
                        <div className={`${
                                    showingNavigationDropdown
                                        ? "link_wrapper_mobile_active sm:link_wrapper"
                                        : "link_wrapper_mobile sm:link_wrapper"
                                }`}>
                            <NavLink
                                href={route("dashboard")}
                                active={route().current("dashboard")}
                            >
                                Dashboard
                            </NavLink>
                            <NavLink
                                href={route("profile.edit")}
                                active={route().current("profile.edit")}
                            >
                                Profile
                            </NavLink>
                            <NavLink
                                href={route("logout")}
                                method="post"
                                as="button"
                            >
                                Log Out
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}
