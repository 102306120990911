import PropTypes from "prop-types";
export default function DangerButton({
    type = "submit",
    className = "",
    processing,
    size = "medium",
    children,
    onClick,
}) {
    const buttonSize = {
        small: " px-2 py-1",
        medium: " px-4 py-2",
        large: " px-6 py-4",
    };
    return (
        <button
            type={type}
            onClick={onClick}
            className={
                `danger_btn ${buttonSize[size]}  ${
                    processing && "opacity-25"
                } ` + className
            }
            disabled={processing}
        >
            {children}
        </button>
    );
}
DangerButton.propTypes = {
    /**
     * Is this the type button default submit
     */
    type: PropTypes.string,
    /**
     * What styles do you want to add
     */
    className: PropTypes.string,
    /**
     * This Disables the button to avoid double clicking
     */
    processing: PropTypes.bool,
    /**
     * How large should the button be based on padding?
     */
    children: PropTypes.any,
    /**
     * How large should the button be based on padding?
     */
    size: PropTypes.oneOf(["small", "medium", "large"]),
    /**
     * Optional click handler
     */
    onClick: PropTypes.func,
};

DangerButton.defaultProps = {
    type: "submit",
    className: "",
    size: "medium",
    processing: false,
    onClick: undefined,
};
