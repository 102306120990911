import { proxy } from "valtio";

const state = proxy({
    months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
    ],
    days: ["S", "M", "T", "W", "T", "F", "S"],
    monthsData: [
        {
            month: "January",
            index: 0,
        },
        {
            month: "Febuary",
            index: 1,
        },
        {
            month: "March",
            index: 2,
        },
        {
            month: "April",
            index: 3,
        },
        {
            month: "May",
            index: 4,
        },
        {
            month: "June",
            index: 5,
        },
        {
            month: "July",
            index: 6,
        },
        {
            month: "August",
            index: 7,
        },
        {
            month: "September",
            index: 8,
        },
        {
            month: "October",
            index: 9,
        },
        {
            month: "November",
            index: 10,
        },
        {
            month: "December",
            index: 11,
        },
    ],
    holidays: [],
    current_view:0,
    year: new Date().getFullYear(),
    selected_month: "Jan",
    user_direction:0,
    swipe_direction:null
});
export default state;
