import AuthenticatedLayout from "../Layouts/AuthenticatedLayout";
import { Head } from "@inertiajs/react";
import Calendar from "../Components/Calendar";
import EventList from "../Components/EventList";
import TodoList from "../Components/TodoList";
export default function Dashboard(props) {
    return (
        <AuthenticatedLayout
            auth={props.auth}
            errors={props.errors}
            mainClass='dashboard-container'
            wrapperClass=''
        >
            <Head title="Dashboard" />

                <div className="dashboard">
                    <section className="dashborad-wrapper">
                        <article className="dashborad-wrapper-left">
                            <Calendar />
                            <TodoList />
                        </article>
                        <article className="dashborad-wrapper-right">
                            <EventList />
                        </article>
                    </section>
                </div>
        </AuthenticatedLayout>
    );
}
