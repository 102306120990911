import PropTypes from "prop-types";
import { forwardRef, useEffect, useRef } from "react";

const TextInput = forwardRef((
    {
        type = "text",
        className,
        isFocused,
        handleChange,
        ...props
    },
    ref
)=> {
    const input = ref ? ref : useRef();

    useEffect(() => {
        if (isFocused) {
            input.current.focus();
        }
    }, []);

    return (
        <div className="flex flex-col items-start">
            <input
                type={type}
                className={
                    `border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 rounded-md shadow-sm ` +
                    className
                }
                ref={input}
                onChange={(e) => handleChange(e)}
                {...props}
            />
        </div>
    );
})
export default TextInput
TextInput.propTypes = {
    /**
     * Is this the type button default submit
     */
    type: PropTypes.string,
    /**
     * What is the name of the input
     */
    name: PropTypes.string,
    /**
     * What is the id for the input
     */
    id: PropTypes.string,
    /**
     * What is the input value
     */
    value: PropTypes.string,
    /**
     * tailwind styles are added here
     */
    className: PropTypes.string,
    /**
     * is autocomplete true
     */
    autoComplete: PropTypes.string,
    /**
     * is the input field required
     */
    required: PropTypes.bool,
    /**
     * is the input currently in focus
     */
    isFocused: PropTypes.bool,
    /**
     * This is the event handler
     */
    handleChange: PropTypes.func,
};

TextInput.defaultProps = {
    type: "text",
    name: "",
    id: "",
    value: "",
    className: "",
    autoComplete: undefined,
    required: undefined,
    isFocused: undefined,
    handleChange: undefined,
};
