import { useEffect,useState } from "react";
const useBreakPoint = (point) => {
    const [breakPoint, setBreakPoint] = useState(
        window.matchMedia(`(min-width: ${point}px)`).matches
    );
    const resizeHandler = () => {
        setBreakPoint(window.matchMedia(`(min-width: ${point}px)`).matches);
    };

    useEffect(() => {
        window.addEventListener("resize", resizeHandler);
        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener("resize", resizeHandler);
        };
    }, [point]);
    return breakPoint;
};
export default useBreakPoint;
