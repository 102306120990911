import DayEventModule from "./DayEventsModule";
import { RxCaretLeft, RxCaretRight } from "react-icons/rx";
import state from "../../Store";
import { useSnapshot } from "valtio";
import useStateMethods from "../../Hooks/useStateMethods";
import { Transition } from "@headlessui/react";
import { Fragment, useMemo } from "react";
import useBreakPoint from "../../Hooks/useBreakPoint";
const EventList = () => {
    const snap = useSnapshot(state);
    const { DayEventModule_array, previousView, nextView } = useStateMethods();
    const breakPoint = useBreakPoint(1280);
    return (
        snap?.today && (
            <div className="event-wrapper">
                {DayEventModule_array.map((props, key) => (
                    <Transition
                        show={key === snap.current_view || breakPoint}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 z-0"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100 z-0"
                        leaveTo="transform opacity-0"
                        className={"content"}
                        key={key}
                    >
                        <DayEventModule
                            {...props}
                            inView={
                                key === snap.current_view ? "current_view" : ""
                            }
                        />
                    </Transition>
                ))}
                <div className="flex xl:hidden mb-4">
                    <span className="calendar-inputs">
                        <RxCaretLeft
                            key={"view-previous"}
                            onClick={previousView}
                            size={"48"}
                        />
                        <RxCaretRight
                            key={"view-next"}
                            onClick={nextView}
                            size={"48"}
                        />
                    </span>
                </div>
            </div>
        )
    );
};
export default EventList;
EventList.propTypes = {};

EventList.defaultProps = {};
