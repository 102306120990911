import Moment from "react-moment";
import { returnArray } from "../../Utils";
import useStateMethods from "../../Hooks/useStateMethods";
const DayEventsModule = ({
    title,
    dateInfo,
    eventArray,
    tabColor,
    events,
    inView,
}) => {
    const { swipeHandlers } = useStateMethods();
    return (
        <section className={`event-container ${inView}`}>
            <article className={`${tabColor || ""} event-tab `}>
                <p>
                    <span className="font-semibold">{title}</span>
                    {" is "}
                    <span>
                        <Moment format="MMMM DD, YYYY">
                            {new Date(dateInfo)}
                        </Moment>
                    </span>
                </p>
            </article>
            <ul className="event-list" {...swipeHandlers}>
                {eventArray.map((h, inx) => (
                    <li className="event-list-container" key={inx}>
                        <p className="event-list-time">{h}</p>

                        <span className="event-list-events">
                            {/* <span className="event-w-bar"></span> */}
                            {returnArray(60).map((m) => (
                                <span
                                    key={m}
                                    className="hover:bg-event inline-block min-h-[1px] cursor-pointer"
                                ></span>
                            ))}
                            {events &&
                                events.map((event, key) => {
                                    return (
                                        <span className="event-items" key={key}>
                                            {"event"}
                                        </span>
                                    );
                                })}
                        </span>
                    </li>
                ))}
            </ul>
        </section>
    );
};
export default DayEventsModule;
