import PropTypes from "prop-types";
import { Link } from "@inertiajs/react";

export default function NavLink({ href, active, children, ...props }) {
    return (
        <Link
            href={href}
            className={
                active
                    ? "active_nav"
                    : "inactive_nav"
            }
            {...props}
        >
            {children}
        </Link>
    );
}
NavLink.propTypes = {
    /**
     * This is the url to the link
     */
    href: PropTypes.string.isRequired,
    /**
     * This adds the avtive styles for the link
     */
    active: PropTypes.bool,

    /**
     * What is the target window
     */
    target: PropTypes.oneOf(["_blank", "_self", "new", undefined]),
    /**
     * The link element shown to the user goes here
     */
    children: PropTypes.any,
};

NavLink.defaultProps = {
    href: "",
    active: false,
    children: undefined,
};
