import PropTypes from "prop-types";
import { Link } from '@inertiajs/react';

export default function ResponsiveNavLink({ method = 'get', as = 'a', href, active = false, children }) {
    return (
        <Link
            method={method}
            as={as}
            href={href}
            className={`w-full flex items-start pl-3 pr-4 py-2 border-l-4 ${
                active
                    ? 'border-indigo-400 text-indigo-700 bg-indigo-50 focus:text-indigo-800 focus:bg-indigo-100 focus:border-indigo-700'
                    : 'border-transparent text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300'
            } text-base font-medium focus:outline-none transition duration-150 ease-in-out`}
        >
            {children}
        </Link>
    );
}
ResponsiveNavLink.propTypes = {
    /**
     * This is the url to the link
     */
    href: PropTypes.string.isRequired,
    /**
     * This adds the avtive styles for the link
     */
    active: PropTypes.bool,

    /**
     * What is the target window
     */
    method:PropTypes.oneOf(['get', 'post']),

    /**
     * What is the target window
     */
    as:PropTypes.string,
    /**
     * The link element shown to the user goes here
     */
    children: PropTypes.any,
};

ResponsiveNavLink.defaultProps = {
    href: "",
    target:'self',
    active:false,
    children: undefined,
};
