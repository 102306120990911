import { Link, Head } from "@inertiajs/react";
import ApplicationLogo from "../Components/ApplicationLogo";
export default function Welcome(props) {
    return (
        <>
            <Head title="Welcome" />
                <div className="flex z-10 fixed p-6 text-white">
                    <ApplicationLogo />
                </div>
            <div className="welcome-container items-center p-4">
                <div className="flex relative z-10 flex-col items-center gap-4 text-white text-center">
                <p className="text-4xl">Thank You for Your Interest in CalenTrio</p>
                <p className="text-lg">To learn more about CalenTrio, please follow the link below.</p>
                    <section>
                        {" "}
                        {props.auth.user ? (
                            <Link
                                href={route("dashboard")}
                                className="welcome_btn  text-lg"
                            >
                                CalenTrio Home
                            </Link>
                        ) : (
                            <>
                                <Link
                                    href={route("login")}
                                    className="welcome_btn font-bold text-lg mr-4"
                                >
                                    Log in
                                </Link>

                                <Link
                                    href={route("register")}
                                    className="welcome_btn font-bold text-lg "
                                >
                                    Register
                                </Link>
                            </>
                        )}
                    </section>
                </div>
            </div>
        </>
    );
}
