import PropTypes from "prop-types";
import { useState } from "react";
import { TfiPlus } from "react-icons/tfi";
import Checkbox from "../../Components/Checkbox";
const TodoList = () => {
    return (
        <section className="todo-container">
            <div className="todo-controller">
                <span>To Do</span>
                <span className="todo-btn">
                    <TfiPlus />
                </span>
            </div>
            <ul className="todo-list-container">
                {[1, 2, 3, 4].map((item) => (
                    <li key={item} className="todo-list-items">
                        <Checkbox id={`item-${item}`} />
                        <label htmlFor={`item-${item}`}>
                            {" "}
                            {"list item-"}
                            {item}
                        </label>
                    </li>
                ))}
            </ul>
        </section>
    );
};
export default TodoList;
TodoList.propTypes = {};

TodoList.defaultProps = {};
