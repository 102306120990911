import Moment from "react-moment";
const EventContainer = ({name:nm, end_date, start_date, end_time, start_time }) => {
    return (
        <div className="text-black">
            <section className="border-b border-black uppercase p-4 bg-blue-400 text-white ">
                <span className="px-2 block">
                    {end_date != start_date ? (
                        <>
                            <Moment format="DD">{start_date}</Moment>
                            {" - "}
                            <Moment format="DD">{end_date}</Moment>
                        </>
                    ) : (
                        <>
                            <Moment format="DD">{start_date}</Moment>
                        </>
                    )}{" "}
                    {nm}
                </span>
            </section>
            <section className="p-4">
                starts at {start_time} and ends at {end_time}
            </section>
        </div>
    );
};

export default EventContainer;
