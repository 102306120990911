import state from "./../Store";
import { getHoursInDay, setCalendar } from "./../Utils";
import { useSnapshot } from "valtio";
import { useCallback, useMemo } from "react";
import CalendarGrid from "../Components/Calendar/CalendarGrid";
import { useSwipeable } from "react-swipeable";
const useStateMethods = () => {
    const snap = useSnapshot(state);
    const CALENDAR_VIEW = useCallback(
        (month, year, today = new Date()) => {
            const cal = setCalendar(month, year, today, snap.holidays);
            return (
                <>
                    <ul className="calendar-grid calendar-daysOfTheWeek">
                        {snap?.days.map((day, key) => (
                            <li key={`${key}_${day}`}>{day}</li>
                        ))}
                    </ul>
                    <CalendarGrid cal={cal} />
                </>
            );
        },
        [snap]
    );
    let tomorrow = useMemo(() => {
        if (snap.today) {
            const tmr = new Date();
            tmr.setDate(snap.today.getDate() + 1);
            return tmr;
        }
        return "...awaiting";
    }, [snap]);

    const addWeeks = useCallback(
        (date, weeks) => {
            const newDate = new Date(date);
            newDate.setDate(date.getDate() + weeks * 7);
            return newDate;
        },
        [snap]
    );

    const DayEventModule_array = useMemo(
        () => [
            {
                title: "Tomorrow",
                dateInfo: tomorrow,
                eventArray: getHoursInDay(),
                events: snap.holidays,
                tabColor: "border-l-4 border-solid border-red-400",
            },
            {
                title: "Next Week",
                dateInfo: addWeeks(snap.today, 1),
                eventArray: getHoursInDay(),
                events: snap.holidays,
                tabColor: "border-l-4 border-solid border-yellow-400",
            },
            {
                title: "Today",
                dateInfo: addWeeks(snap.today, 1),
                eventArray: getHoursInDay(),
                events: snap.holidays,
                tabColor: "border-l-4 border-solid border-blue-400",
            },
        ],
        [snap]
    );
    const nextMonth = useCallback(() => {
        state.currentMonth = snap.currentMonth < 11 ? snap.currentMonth + 1 : 0;
        state.currentYear =
            snap.currentMonth < 11 ? snap.currentYear : snap.currentYear + 1;
    }, [snap]);

    const previousMonth = useCallback(() => {
        state.currentMonth = snap.currentMonth > 0 ? snap.currentMonth - 1 : 11;
        state.currentYear =
            snap.currentMonth > 0 ? snap.currentYear : snap.currentYear - 1;
    }, [snap]);

    const previousView = useCallback(() => {
        state.current_view =
            snap.current_view === 0
                ? DayEventModule_array.length - 1
                : snap.current_view - 1;
    }, [snap]);
    const nextView = useCallback(() => {
        state.current_view =
            (snap.current_view + 1) % DayEventModule_array.length;
    }, [snap]);

    const swipeConfig = {
        delta: 30, // min distance(px) before a swipe starts. *See Notes*
        preventScrollOnSwipe: false, // prevents scroll during swipe (*See Details*)
        trackTouch: true, // track touch input
        trackMouse: false, // track mouse input
        rotationAngle: 0, // set a rotation angle
        swipeDuration: Infinity, // allowable duration of a swipe (ms). *See Notes*
        touchEventOptions: { passive: true }, // options for touch listeners (*See Details*)
    };
    const swipeHandlers = useSwipeable({
        onSwipedLeft: previousView,
        onSwipedRight: nextView,
        ...swipeConfig,
    });
    return {
        tomorrow,
        DayEventModule_array,
        addWeeks,
        previousMonth,
        nextMonth,
        nextView,
        previousView,
        CALENDAR_VIEW,
        swipeHandlers,
    };
};
export default useStateMethods;
