import PropTypes from "prop-types";
export default function Checkbox({ name, value, handleChange,...props }) {
    return (
        <input
            type="checkbox"
            name={name}
            value={value}
            className="rounded border-gray-300 text-indigo-600 shadow-sm focus:ring-indigo-500"
            onChange={(e) => handleChange(e)}
            {...props}
        />
    );
}
Checkbox.propTypes = {
    /**
     * What is the name of the input
     */
    name: PropTypes.string,
    /**
     * What is the input value
     */
    value: PropTypes.string,
    /**
     * This is the event handler
     */
    handleChange: PropTypes.func,
};

Checkbox.defaultProps = {
    name: "",
    value: "",
    handleChange: undefined,
};
