
import { RxCaretLeft, RxCaretRight } from "react-icons/rx";
import state from "../../Store";
import { useSnapshot } from "valtio";
import useStateMethods from "../../Hooks/useStateMethods";
const CalendarModule = () => {
    const snap = useSnapshot(state);
    const { previousMonth, nextMonth, CALENDAR_VIEW } = useStateMethods();
    const getNextMonth = (month) => (month < 11 ? month + 1 : 0);
    const selectAMonth = () => {
        console.log("clicked");
    };
    return (
        <>
            <section className="calendar-container">
                <article className="calendar-controller">
                    <p className="calendar-title">
                        {snap?.monthsData[snap?.currentMonth].month}{" "}
                        {snap?.currentYear}
                    </p>

                    <span className="calendar-inputs">
                        <RxCaretLeft onClick={previousMonth} size={"24"} />
                        {/* <RxDotFilled onClick={selectAMonth} /> */}
                        <RxCaretRight onClick={nextMonth} size={"24"} />
                    </span>
                </article>

                {CALENDAR_VIEW(snap.currentMonth, snap.currentYear, snap.today)}
            </section>
            <section className="calendar-container">
                <article className="calendar-controller">
                    <p className="calendar-title">
                        {
                            snap?.monthsData[getNextMonth(snap?.currentMonth)]
                                .month
                        }{" "}
                        {snap.currentMonth === 11
                            ? snap.currentYear + 1
                            : snap.currentYear}
                    </p>
                </article>

                {CALENDAR_VIEW(
                    getNextMonth(snap.currentMonth),
                    snap.currentMonth === 11
                        ? snap.currentYear + 1
                        : snap.currentYear,
                    snap.today
                )}
            </section>
        </>
    );
};
export default CalendarModule;
