import moment from "moment";
export function generate_year_range(start = 0, end = 0) {
    var years = [];
    for (var year = start; year <= end; year++) {
        years.push(year);
    }
    return years;
}
export function daysInMonth(iMonth, iYear) {
    return 32 - new Date(iYear, iMonth, 32).getDate();
}
export function mDate(date) {
    return moment(new Date(date));
}
export function setCalendar(month, year, today = new Date(), events) {
    const firstDay = new Date(year, month).getDay();
    let date = 1;
    let nextMonth = 1;
    const calendarData = [];
    const hasEvent = (date, events, callBackClass) => {
        const dateChecker = mDate(`${month}/${date}/${year}`);
        let returnEvent = { class: callBackClass };
        let returnArray = [];
        for (let i = 0; i < events.length; i++) {
            switch (true) {
                case mDate(events[i].start_date) <= dateChecker &&
                    mDate(events[i].end_date) >= dateChecker:
                    returnArray.push(events[i]);
                    break;
                default:
                    break;
            }
        }
        if (returnArray.length > 0) {
            returnEvent = {
                class: "bg-blue-300 rounded-full cursor-pointer text-white",
                eventDate: returnArray,
            };
        }
        return { ...returnEvent };
    };
    for (let i = 0; i < 6; i++) {
        for (let j = 0; j < 7; j++) {
            if (i === 0 && j < firstDay) {
                calendarData.push({
                    day:
                        daysInMonth((month - 1) % 12, year) +
                        1 -
                        (firstDay - j),
                    class: "out_date date_properties",
                });
            } else if (date > daysInMonth(month, year)) {
                calendarData.push({
                    day: nextMonth,
                    class: "out_date date_properties",
                });
                nextMonth++;
            } else {
                if (
                    date === today.getDate() &&
                    year === today.getFullYear() &&
                    month === today.getMonth()
                ) {
                    calendarData.push({
                        day: date,
                        ...hasEvent(
                            date,
                            events,
                            "todays_date date_properties"
                        ),
                    });
                } else {
                    calendarData.push({
                        day: date,
                        ...hasEvent(date, events, "current_date date_properties"),
                    });
                }

                date++;
            }
        }
    }
    return calendarData;
}
export function __getHoursInDay() {
    var result = []; // Results will go here
    var nowHour = new Date().getHours() % 12 || 12; // Get current hour of the day

    // Loop from current hour number to 23
    for (var i = nowHour; i < 24; i++) {
        const end = i >= 12 ? "PM" : "AM";
        const h = i % 12;
        result.push(h !== 0 ? h + " " + end : 12 + " " + end); // Put loop counter into array with "00" next to it
    }
    return result;
}

export function getHoursInDay() {
    var result = []; // Results will go here
    var nowHour = new Date().getHours() % 12 || 12; // Get current hour of the day

    // Loop from current hour number to 23
    for (var i = nowHour; i < 24; i++) {
        const end = i >= 12 ? ":00" : ":00";
        const h = i % 12;
        result.push(h !== 0 ? h<10?`0${h}${end}`: `${h}${end}` : `12${end}`); // Put loop counter into array with "00" next to it
    }
    return result;
}
export const returnArray = (number) => {
    return Array.apply(null, { length: number }).map(Function.call, Number);
};
