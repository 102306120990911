import ApplicationLogo from "../Components/ApplicationLogo";
import { Link } from "@inertiajs/react";

export default function Guest({ children }) {
    return (
        <>
            <div className="fixed z-10 p-6">
                <Link href="/">
                    <ApplicationLogo className="fill-current text-white" />
                </Link>
            </div>
            <div className="min-h-screen flex flex-col justify-center items-center dashboard-container">
                <div className="relative z-10 w-full sm:max-w-md mt-6 px-6 py-4 bg-white shadow-md overflow-hidden sm:rounded-lg">
                    {children}
                </div>
            </div>
        </>
    );
}
