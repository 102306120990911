import Dropdown from "../Dropdown";
import EventContainer from "../EventList/EventContainer";
const CalendarGrid = ({ cal }) => {
    return (
            <ul className="calendar-grid">
                {cal.map((cal, key) =>
                    cal?.eventDate ? (
                        <li key={`${key}_${cal.day}`} className={cal.class}>
                            <Dropdown>
                                <Dropdown.Trigger>{cal.day}</Dropdown.Trigger>

                                <Dropdown.Content align="left" width="w-64">
                                    <ul className="py-2">
                                        {cal?.eventDate.map((data, key) => (
                                            <li key={key} className="mb-2">
                                                <EventContainer {...data} />
                                            </li>
                                        ))}
                                    </ul>
                                </Dropdown.Content>
                            </Dropdown>
                        </li>
                    ) : (
                        <li key={`${key}_${cal.day}`} className={cal.class}>
                            {cal.day}
                        </li>
                    )
                )}
            </ul>
    );
};
export default CalendarGrid;
