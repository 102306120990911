// Context/reducer.js

// import React, { useReducer } from "react";

export const initialState = {
    months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
    ],
    days: ["S", "M", "T", "W", "T", "F", "S"],
    holidays: [],
    _holidays: [
        {
            name: "David's Birthday",
            start_date: "1/17/2023",
            end_date: "1/18/2023",
            start_time: "7PM",
            end_time: "2AM",
        },
        {
            name: "Valentine's Day",
            start_date: "2/14/2023",
            end_date: "2/14/2023",
            start_time: "12AM",
            end_time: "12AM",
        },
        {
            name: "Zorah's Birthday",
            start_date: "2/14/2023",
            end_date: "2/14/2023",
            start_time: "4PM",
            end_time: "8AM",
        },
        {
            name: "black history month",
            start_date: "2/01/2023",
            end_date: "2/28/2023",
            start_time: "12AM",
            end_time: "12AM",
        },
    ],
};

export const AuthReducer = (initialState, action) => {
    switch (action.type) {
        case "UPDATE_STATE":
            return {
                ...initialState,
                ...action.payload,
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
