import { useState } from "react";
import Nav from "../Components/Navigation/Nav";

export default function Authenticated({
    auth,
    header,
    mainClass,
    wrapperClass,
    children,
}) {
    const [showingNavigationDropdown, setShowingNavigationDropdown] =
        useState(false);

    return (
        <div className={`${wrapperClass || ""} site-wrapper`}>
            <Nav auth={auth} />

            <main className={"main-wrapper"}>
                {header && (
                    <header className="bg-site_background shadow">
                        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                            {header}
                        </div>
                    </header>
                )}
                {children}
            </main>
        </div>
    );
}
